body,
a,p,div,span,
h1, h2, h3, h4, h5, h6,
hr,
form, input, select, textarea, button,
img,
table, table tr, table tr td,
ul, ul li{
	color: #333;
}

body {
	margin: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.body-content {
	font-weight: normal;
	padding: 0;
	margin: 0;
	min-height: 400px;
}

.body-content a{
	color: #158d9a;/*#3d7a85;*/
}
.body-content a:hover{
	color: #0b646e;
	text-decoration: underline;
}

table,
table tbody{
	margin: 0;
	margin-bottom: 0;
}
table,
table thead th,
table thead td,
table tbody th,
table tbody td,
table tfoot th,
table tfoot td {
	padding: 0;
	margin-bottom: 0;
}
table tbody tr,
table tbody td{
	padding: 0;
	margin: 0;
}
    
table tbody tr,
table tbody td
table tr,
table td{
	font-family: inherit;
	padding: 0;
}
table thead tr th{
	font-weight: 800;
}
table tbody tr {
	background-color: #fff;
}
table tbody tr:nth-child(even) {
	background-color: #efefef;
}
table.small tr th,
table.small tr td,
table.small tr{
	padding: 0;
}
table.small.plr-10 tr td:first-child{
	padding-left: 10px;
}
table.small.plr-10 tr td:last-child{
	padding-right: 10px;
}

table.unstriped,
table.unbordered,
table.unstriped.unbordered{
   border-bottom: none;
   background-color: transparent;
}
table.unstriped thead,
table.unstriped tbody,
table.unstriped tfoot,
table.unbordered thead,
table.unbordered tbody,
table.unbordered tfoot,
table.unstriped.unbordered thead,
table.unstriped.unbordered tbody,
table.unstriped.unbordered tfoot {
  border: none;
  background-color: transparent;
}

table.unstriped tbody tr:nth-child(even),
table.unbordered tbody tr:nth-child(even),
table.unstriped.unbordered tbody tr:nth-child(even) {
  border-bottom: none;
  background-color: transparent;
}
 table.unstriped tbody tr,
 table.unbordered tbody tr,
 table.unstriped.unbordered tbody tr {
   border-bottom: none;
   background-color: transparent;
}
    
hr {
	max-width: none;
}

.swal-modal {
  position: fixed;
  left: 50%;
  top: 100px; /* Default position for large and medium screens */
  transform: translateX(-50%); /* Horizontal centering */
  
  width: 700px; /* Set your desired width */
  max-width: 90%; /* Ensures it stays within the screen size on mobile */
}
.swal-title {
	font-family: 'Raleway', sans-serif;
  color: #333;
  font-size: 18px;
  font-weight: 700;
	text-transform: uppercase;
}
.swal-text {
	font-family: 'Raleway', sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}
.swal-content,
.swal-content * {
	font-family: 'Raleway', sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}
.swal-content ul li,
.swal-content ol li{
  margin: 0 0 0 0
}
.swal-content ul li:last-child,
.swal-content ol li:last-child {
  margin: 0
}

.full-width-video {
  width: 100%;
  height: 100%;
}

.video-view-modal {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay background */
  z-index: 3000; /* Higher z-index to be on top of the video list */
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.video-container video {
  width: 100%;
  height: auto;
  display: block;
}
.video-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.video-content {
  flex: 1; /* Allow the content to grow and take remaining space */
}
.videom-wrapper img {
  max-width: 100%;
  height: auto;
}
.video-donate-button {
  align-self: center; /* Center-align the button */
  margin-top: auto; /* Push the button toward the bottom */
}


/* Add these styles to your CSS file */

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 0;
}
.page-item {
  margin: 0 5px;
}
.page-item:last-child {
  margin: 0 5px;
}

.page-link,
a.page-link,
button.page-link {
  display: block;
  padding: 10px 15px;
  color: #fff !important;
  background: #0073E6;
  text-decoration: none;
  border: 1px solid #0073E6;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.page-item.active .page-link,
.page-item.active a.page-link,
.page-item.active button.page-link {
  color: #333 !important;
  background: #eee !important;
  border: 1px solid #aaa !important;
}
	
.page-link:hover,
a.page-link:hover,
button.page-link:hover {
  color: #fff !important;
  background: #0060bf !important;
  border: 1px solid #0060bf !important;
  text-decoration: none;
}



.close-button {
    right: 10px;
    top: 0;
    font-size: 2em;
    line-height: 1;
	color: #49939F;
}
.close-button.small {
    right: 5px;
    font-size: 1.5em;
    line-height: 1;
}
.close-button:hover, .close-button:focus,
.close-button.small:hover, .close-button.small:focus {
	color: #3d7a85;
}

a.hover-opacity-50 {
	color: #333;
	background: transparent;
}
a.hover-opacity-50:hover{
	background: transparent;
	opacity: 0.5;
	text-decoration: none;
}

a.no-underline:hover{
	text-decoration: none;
}
a.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: none;
}

.parent-of-sup {
  position: relative;
}

.parent-of-sup > * {
	position:absolute;
  top: 0.6em;
  right: -1em;
}
#chart-container {
  z-index: 0;
}

@media only screen and (max-width:1040px) {
}
@media only screen and (max-width:820px) {
}
@media only screen and (max-width:720px) {
}
@media only screen and (max-width:640px) {
	.page-header{
	    font-size: 16px;
	}
	.page-text{
	    font-size: 14px;
	}
	
  .swal-modal {
    top: 50px;
  }
}
@media only screen and (max-width:520px) {

}
@media only screen and (max-width:420px) {
	table.small tr th,
	table.small tr td,
	table.small tr{
		padding: 2px;
	}
}
