.footer{
	flex-shrink: 0;
}

.footer a{
	letter-spacing: 1px;
	color: #158d9a;
}
.footer a:hover{
	color: #0b646e;
	text-decoration: underline;
}
@media only screen and (max-width:640px) {
	
}
