/* 5px padding mobile responsive*/
.p-5l-0s{
	padding: 5px;
}
.pl-5l-0s{
	padding-left: 5px;
}
.pr-5l-0s{
	padding-right: 5px;
}
.plr-5l-0s{
	padding-left: 5px;
	padding-right: 5px;
}

.pl-5l-5m-0s{
	padding-left: 5px;
}
.pl-5l-0m-0s{
	padding-left: 5px;
}
.pl-0l-5m-0s{
	padding-left: 0;
}

.pr-5l-5m-0s{
	padding-right: 5px;
}
.pr-5l-0m-0s{
	padding-right: 5px;
}
.pr-0l-5m-0s{
	padding-right: 0;
}


.pt-5l-0s{
	padding-top: 5px;
}
.pb-5l-0s{
	padding-bottom: 5px;
}
.ptb-5l-0s{
	padding-top: 5px;
	padding-bottom: 5px;
}
/* 10px padding mobile responsive*/
.pt-0l-10s{
	padding-top: 0;
}
.pl-10l-0s{
	padding-left: 10px;
}
.pr-10l-0s{
	padding-right: 10px;
}
.ptb-10l-5s{
	padding-top: 10px;
	padding-bottom: 10px;
}
.pt-10l-5s{
	padding-top: 10px;
}
.plr-10l-0s{
	padding-left: 10px;
	padding-right: 10px;
}
.mtb-10l-5s{
	margin-top: 10px;
	margin-bottom: 10px;
}
.mt-10l-5s{
	margin-top: 10px;
}
/* 20px padding mobile responsive*/
.p-20l-10s{
	padding: 20px;
}
.pt-0l-20s{
	padding-top: 0;
}
.pt-20l-10s{
	padding-top: 20px;
}
.pb-20l-10s{
	padding-bottom: 20px;
}
.plr-20l-10s{
	padding-left: 20px;
	padding-right: 20px;
}
.plr-20l-0s{
	padding-left: 20px;
	padding-right: 20px;
}
.ptb-20l-10s{
	padding-top: 20px;
	padding-bottom: 20px;
}
.ptb-20l-15s{
	padding-top: 20px;
	padding-bottom: 20px;
}
.mt-20l-10s{
	margin-top: 20px;
}
/* padding for body due to navigation */
.pt-70l-110m-50s{
	padding-top: 70px;
}
/* padding for footer due to bottom sticky nav */
.pb-100l-80s{
	padding-bottom: 100px;
}
.pb-120l-100s{
	padding-bottom: 120px;
}
.pb-170l-150s{
	padding-bottom: 170px;
}

@media only screen and (max-width:1024px) {
	.pl-5l-5m-0s{
		padding-left: 5px;
	}
	.pl-5l-0m-0s{
		padding-left: 0;
	}
	.pl-0l-5m-0s{
		padding-left: 5px;
	}
	
	.pr-5l-5m-0s{
		padding-right: 5px;
	}
	.pr-5l-0m-0s{
		padding-right: 0;
	}
	.pr-0l-5m-0s{
		padding-right: 5px;
	}
}
@media only screen and (max-width:920px) {
	/* padding for body due to navigation */
	.pt-70l-110m-50s{
		padding-top: 70px;
	}
}
@media only screen and (max-width:820px) {
}
@media only screen and (max-width:720px) {
}
@media only screen and (max-width:680px) {
}
@media only screen and (max-width:640px) {
	/* 5px padding mobile responsive*/
	.pl-5l-0s{
		padding-left: 0;
	}
	.pr-5l-0s{
		padding-right: 0;
	}
	.plr-5l-0s{
		padding-left: 0;
		padding-right: 0;
	}
	.pl-5l-5m-0s{
		padding-left: 0;
	}
	.pl-5l-0m-0s{
		padding-left: 0;
	}
	.pl-0l-5m-0s{
		padding-left: 0;
	}
	
	.pr-5l-5m-0s{
		padding-right: 0;
	}
	.pr-5l-0m-0s{
		padding-right: 0;
	}
	.pr-0l-5m-0s{
		padding-right: 0;
	}
	/* 10px padding mobile responsive*/
	.pt-0l-10s{
		padding-top: 10px;
	}
	.pl-10l-0s{
		padding-left: 0;
	}
	.pr-10l-0s{
		padding-right: 0;
	}
	.plr-10l-0s{
		padding-left: 0;
		padding-right: 0;
	}
	.pt-10l-5s{
		padding-top: 5px;
	}
	.ptb-10l-5s{
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.mt-10l-5s{
		margin-top: 5px;
	}
	.mtb-10l-5s{
		margin-top: 5px;
		margin-bottom: 5px;
	}
	/* 20px padding mobile responsive*/
	.p-20l-10s{
		padding: 10px;
	}
	.pt-0l-20s{
		padding-top: 20px;
	}
	.pt-20l-10s{
		padding-top: 10px;
	}
	.pb-20l-10s{
		padding-bottom: 10px;
	}
	.plr-20l-10s{
		padding-left: 10px;
		padding-right: 10px;
	}
	.plr-20l-0s{
		padding-left: 0;
		padding-right: 0;
	}
	.ptb-20l-10s{
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.ptb-20l-15s{
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.mt-20l-10s{
		margin-top: 10px;
	}
	/* padding for body due to navigation */
	.pt-70l-110m-50s{
		padding-top: 50px;
	}
	/* padding for footer due to bottom sticky nav */
	.pb-100l-80s{
		padding-bottom: 80px;
	}
	.pb-120l-100s{
		padding-bottom: 100px;
	}
	.pb-170l-150s{
		padding-bottom: 150px;
	}
}
@media only screen and (max-width:580px) {
}
@media only screen and (max-width:520px) {
}
@media only screen and (max-width:480px) {
}
@media only screen and (max-width:420px) {
}
@media only screen and (max-width:380px) {
}